<template>
    <div class="input-text flex items-center gap-x-2" :class="classNames">
        <label v-if="label" class="label">{{ label }}</label>

        <p v-if="preview" class="preview">{{ model || '-' }}</p>

        <TooltipErrors v-else class="w-full" :model="errors">
            <input :id v-model="model" :class="inputClass" :type :placeholder :required :disabled />
        </TooltipErrors>
    </div>
</template>

<script setup lang="ts">
import { ESize, EText } from '@ts/enums/app.enum';
import type { ClassType, StringOrNull } from '@ts/types/app.type';
import TooltipErrors from '@c/TooltipErrors.vue';

/** Interfaces **/
interface Props {
    type?: EText;
    size?: ESize;
    placeholder?: string;
    label?: string;
    required?: boolean;
    disabled?: boolean;
    errors?: string[];
    preview?: boolean;
    inputClass?: ClassType;
}

/** Props **/
const model = defineModel<StringOrNull>({ required: true, default: null });

const props = withDefaults(defineProps<Props>(), {
    type: EText.TEXT,
    size: ESize.DEFAULT,
    required: false,
    disabled: false,
    errors: () => [],
    preview: false,
    inputClass: '',
});
const { size, disabled, errors } = toRefs(props);

/** Data **/
const id: string = useId() as string;

/** Computed **/
const classNames = computed<string[]>(() => [
    toValue(size),
    toValue(errors).length ? 'invalid' : '',
    !toValue(model) ? 'empty' : '',
    toValue(disabled) ? 'disabled' : '',
]);
</script>

<style src="@a/styles/components/input-text.scss" scoped lang="scss" />
